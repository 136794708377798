import _IconWrapper, { IconBaseProps } from './_IconWrapper'

export default function PhoneIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
        <path
          d='M14.6676 11.28V13.28C14.6684 13.4657 14.6303 13.6494 14.5559 13.8196C14.4816 13.9897 14.3725 14.1424 14.2357 14.2679C14.0988 14.3934 13.9373 14.489 13.7614 14.5485C13.5855 14.608 13.3992 14.63 13.2143 14.6133C11.1628 14.3904 9.19226 13.6894 7.46093 12.5667C5.85015 11.5431 4.48449 10.1774 3.46093 8.56667C2.33425 6.82747 1.63309 4.84733 1.41427 2.78667C1.39761 2.60231 1.41952 2.41651 1.4786 2.24108C1.53768 2.06566 1.63265 1.90446 1.75744 1.76775C1.88224 1.63103 2.03414 1.5218 2.20346 1.44701C2.37278 1.37222 2.55583 1.33351 2.74093 1.33333H4.74093C5.06447 1.33015 5.37813 1.44472 5.62344 1.65569C5.86875 1.86666 6.02898 2.15963 6.07427 2.48C6.15868 3.12004 6.31523 3.74848 6.54093 4.35333C6.63063 4.59195 6.65004 4.85128 6.59687 5.10059C6.5437 5.3499 6.42017 5.57874 6.24093 5.76L5.39427 6.60667C6.3433 8.2757 7.72524 9.65763 9.39427 10.6067L10.2409 9.76C10.4222 9.58076 10.651 9.45723 10.9003 9.40406C11.1497 9.35089 11.409 9.3703 11.6476 9.46C12.2524 9.6857 12.8809 9.84225 13.5209 9.92667C13.8448 9.97235 14.1405 10.1355 14.352 10.385C14.5634 10.6345 14.6757 10.953 14.6676 11.28Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </_IconWrapper>
  )
}
