'use client'

import { useEffect, useId, useState } from 'react'
import JobListFilterGroup, { JobListFilterGroupProps } from './JobListFilterGroup'
import { cn } from '@/lib/cn'
import FilterIcon from '@/components/icons/FilterIcon'
import XIcon from '@/components/icons/XIcon'
import { useSearchParams } from 'next/navigation'

type JobListFilterProps = {
  filterAriaTitle: string
  resetButtonText: string
  onReset: () => void
  groups: JobListFilterGroupProps[]
  searchTerm: string
}

export default function JobListFilter({
  filterAriaTitle,
  groups,
  resetButtonText,
  searchTerm,
  onReset,
}: JobListFilterProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [forceRegionShown, setForceRegionShown] = useState(false)

  // Force region to be shown on desktop - aria-hidden=false
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) setForceRegionShown(true)
      else setForceRegionShown(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Get filter count from search params
  const searchParams = useSearchParams()
  const filterCount =
    (searchParams.get('location')?.split(',').length || 0) +
    (searchParams.get('employment')?.split(',').length || 0) +
    (searchParams.get('remote')?.split(',').length || 0)

  // Generate unique ids for the filter toggle button and region
  const id = useId().replaceAll(':', '')
  const buttonId = `button-${id}`
  const regionId = `region-${id}`

  return (
    <div className='mb-6'>
      {/* Mobile filter toggle */}
      <div className='flex justify-end sm:hidden'>
        <button
          id={buttonId}
          aria-expanded={isOpen}
          aria-controls={regionId}
          onClick={() => setIsOpen(!isOpen)}
          className='flex items-center justify-center size-12 p-3 bg-surface-cta rounded-full relative'
        >
          <span
            aria-hidden
            className={cn(
              'text-xs absolute top-0 right-0 block size-4 bg-surface-solid rounded-full transition-opacity duration-100',
              {
                'opacity-0': filterCount === 0,
              },
            )}
          >
            {filterCount || 1}
          </span>
          <span className='size-full relative' aria-hidden>
            <FilterIcon
              className={cn(
                'size-full absolute top-0 left-0 transition-[transform,opacity] duration-100 delay-100',
                {
                  'translate-y-2 opacity-0 delay-0': isOpen,
                },
              )}
            />
            <XIcon
              className={cn(
                'size-full absolute top-0 left-0 transition-[transform,opacity] duration-100 delay-100',
                {
                  'translate-y-2 opacity-0 delay-0': !isOpen,
                },
              )}
            />
          </span>
          <span className='sr-only'>{filterAriaTitle}</span>
        </button>
      </div>

      {/* Filter toggle animator - animate by grid and overflow */}
      <div
        className={cn(
          'grid grid-rows-[0fr] transition-all transform-gpu duration-500 ease-in-out motion-reduce:transition-none sm:block',
          {
            'grid-rows-[1fr]': isOpen,
          },
        )}
      >
        <div className='overflow-hidden'>
          {/* Filter container */}
          <div
            role='region'
            id={regionId}
            aria-labelledby={buttonId}
            aria-hidden={forceRegionShown ? false : !isOpen}
            className={cn(
              'bg-surface-solid w-full sm:max-w-52 mt-4 sm:mt-0 grid gap-6 pl-6 sm:pl-0 sm:pr-6 border-l sm:border-l-0 sm:border-r border-stroke',
            )}
          >
            {groups.map((group, index) => {
              return <JobListFilterGroup key={index} {...group} />
            })}

            {(filterCount > 0 || searchTerm.length > 0) && (
              <button
                className='w-full border border-surface-cta font-bold py-2 px-6 hover:bg-surface-cta transition-colors duration-300'
                onClick={onReset}
              >
                {resetButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
