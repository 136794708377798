import type { Job } from '@/types/job'

// Returns a count of the number of jobs for each option
// filterByType generates a pesudofilter for the requested filterType, by bypassing the requested filterType

export function getOptionsCount(
  jobs: Job[],
  currentFilters: {
    remote: string[]
    employment: string[]
    location: string[]
    availability: string[]
  },
  filterType: 'remote' | 'employment' | 'location' | 'availability',
): { [key: string]: number } {
  // Create a filtered jobs array excluding the current filter type - NOTE: This is not the same as filterByFilterGroup()

  function filterByType(job: Job, type: 'remote' | 'employment' | 'location' | 'availability') {
    // Check if this is the current filterType
    if (type === filterType) return true
    // Return true if no filters are selected for this type
    if (!currentFilters[type].length) return true

    // Check if job matches any of the selected filters
    return currentFilters[type].some((filter) => {
      if (currentFilters[type].find((f) => f.startsWith(`${filter}:`))) return false
      if (type === 'remote') return job.remoteOption?.value === filter
      if (type === 'availability') return job.availabilityType?.value === filter
      if (type === 'employment') return job.employmentType?.value === filter
      if (type === 'location') {
        if (filter.includes(':')) return job.location?.city?.value === filter // If sub-filter, check city
        return job.location?.country?.value === filter // If main filter, check country
      }
      return false
    })
  }

  const filteredJobs = jobs.filter(
    (job) =>
      filterByType(job, 'remote') &&
      filterByType(job, 'employment') &&
      filterByType(job, 'location') &&
      filterByType(job, 'availability'),
  )

  // Count jobs for each option
  return filteredJobs.reduce((acc, job) => {
    const values: string[] = []

    if (filterType === 'remote') {
      values.push(job.remoteOption?.value || '')
    }
    if (filterType === 'availability') {
      values.push(job.availabilityType?.value || '')
    }
    if (filterType === 'employment') {
      values.push(job.employmentType?.value || '')
    }
    if (filterType === 'location') {
      values.push(job.location?.country?.value || '')
      values.push(job.location?.city?.value || '')
    }

    values?.forEach((value) => {
      acc[value] = (acc[value] || 0) + 1
    })

    return acc
  }, {} as { [key: string]: number })
}
