import type { BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'
import BaseImage from '@/components/atoms/BaseImage/BaseImage'
import BaseLink from '@/components/atoms/BaseLink/BaseLink'
import Heading from '@/components/atoms/Heading/Heading'
import Text from '@/components/atoms/Text/Text'
import MailIcon from '@/components/icons/MailIcon'
import PhoneIcon from '@/components/icons/PhoneIcon'

export type PeopleCardProps = {
  image?: BaseImageObject
  imageAltText?: string
  name?: string
  jobTitle?: string
  email?: string
  phone?: string
}

export default function PeopleCard({
  image,
  name,
  jobTitle,
  email,
  phone,
  imageAltText,
}: PeopleCardProps) {
  return (
    <div>
      {image && (
        <BaseImage
          {...image}
          alt={imageAltText || image.alt}
          aspectRatio={1}
          sizes='400px'
          className='mb-6'
        />
      )}
      <Text size='sm' className='mb-1'>
        {jobTitle}
      </Text>
      <Heading size='sm' tag='h3' className='mb-6'>
        {name}
      </Heading>
      {email && (
        <div className='flex gap-1 items-center'>
          <MailIcon className='size-4' />
          <BaseLink
            href={`mailto:${email.replaceAll(' ', '')}`}
            prefetch={false}
            className='hover:text-text-anchor'
          >
            {email}
          </BaseLink>
        </div>
      )}
      {phone && (
        <div className='flex gap-1 items-center mt-3'>
          <PhoneIcon className='size-4' />
          <BaseLink
            href={`tel:${phone.replaceAll(' ', '')}`}
            prefetch={false}
            className='hover:text-text-anchor'
          >
            {phone}
          </BaseLink>
        </div>
      )}
    </div>
  )
}
