'use client'

import { useEffect, useState } from 'react'
import PeopleCard, { PeopleCardProps } from './PeopleCard'
import Container from '@/components/atoms/Container/Container'
import Select from '@/components/atoms/Forms/Select/Select'
import SearchField from '@/components/atoms/Forms/SearchField/SearchField'
import Text from '@/components/atoms/Text/Text'
import Label from '@/components/atoms/Label/Label'
import Heading from '@/components/atoms/Heading/Heading'

type ExpandedPeopleCardProps = {
  id: string
  regions?: string[]
  departments?: string[]
} & PeopleCardProps

export type PeopleCardGridProps = {
  label?: string
  headline?: string
  peopleCards: ExpandedPeopleCardProps[]
  regions: string[]
  departments: string[]
  // Dict values
  allRegions?: string
  allDepartments?: string
  noResults?: string
  searchName?: string
}

export default function PeopleCardGrid({
  label,
  headline,
  peopleCards = [],
  departments,
  regions,
  // Dict values
  allRegions = 'All regions',
  allDepartments = 'All departments',
  noResults = 'No results',
  searchName = 'Search name',
}: PeopleCardGridProps) {
  const [filteredPeople, setFilteredPeople] = useState<ExpandedPeopleCardProps[]>(peopleCards)
  const [departmentFilter, setDepartmentFilter] = useState<string>('*')
  const [regionFilter, setRegionFilter] = useState<string>('*')
  const [searchFilter, setSearchFilter] = useState<string>('')

  // Handle filters
  useEffect(() => {
    // No filters -> show all people
    if (departmentFilter === '*' && regionFilter === '*' && searchFilter === '') {
      return setFilteredPeople(peopleCards)
    }

    // Filter people using AND logic
    const _filteredPeople = peopleCards.filter((person) => {
      if (departmentFilter !== '*' && !person.departments?.includes(departmentFilter)) {
        return false
      }
      if (regionFilter !== '*' && !person.regions?.includes(regionFilter)) {
        return false
      }
      if (
        searchFilter &&
        !person?.name?.toLocaleLowerCase()?.includes(searchFilter.toLocaleLowerCase())
      ) {
        return false
      }
      return true
    })

    setFilteredPeople(_filteredPeople)
  }, [departmentFilter, regionFilter, searchFilter, peopleCards])

  return (
    <Container>
      {/* Intro text */}
      {(headline || label) && (
        <div className='mb-10 2xl:mb-16 grid gap-1 max-w-4xl'>
          <Label>{label}</Label>
          <Heading tag='h2' size='xl'>
            {headline}
          </Heading>
        </div>
      )}

      <div className='max-w-3xl mb-10 2xl:mb-16'>
        {/* Select filters */}
        <div className='grid md:grid-cols-2 gap-6 mb-8'>
          <Select onChange={(e) => setRegionFilter(e.target.value)}>
            <option value='*'>{allRegions}</option>
            {regions?.map((region) => {
              return (
                <option value={region} key={region}>
                  {region}
                </option>
              )
            })}
          </Select>

          <Select onChange={(e) => setDepartmentFilter(e.target.value)}>
            <option value='*'>{allDepartments}</option>
            {departments?.map((department) => {
              return (
                <option value={department} key={department}>
                  {department}
                </option>
              )
            })}
          </Select>
        </div>

        {/* Search filters */}
        <SearchField label={searchName} onChange={(e) => setSearchFilter(e.target.value)} />
      </div>

      {/* Cards */}
      <ul className='grid gap-y-16 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 theme-base-1'>
        {filteredPeople.length === 0 && <Text>{noResults}</Text>}
        {filteredPeople?.map((item) => {
          return (
            <li key={item.id}>
              <PeopleCard {...item} />
            </li>
          )
        })}
      </ul>
    </Container>
  )
}
