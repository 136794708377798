import _IconWrapper, { IconBaseProps } from './_IconWrapper'

export default function CheckMarkIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='none'>
        <path
          d='M10 3L4.5 8.5L2 6'
          stroke='currentColor'
          strokeWidth='0.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </_IconWrapper>
  )
}
