'use client'

import type { Job } from '@/types/job'

import { useParams } from 'next/navigation'
import { useEffect, useState } from 'react'

import { i18n, Locale } from '@/i18n'

import Button from '@/components/atoms/Button/Button'
import Heading from '@/components/atoms/Heading/Heading'
import Text from '@/components/atoms/Text/Text'
import ToolTip from '@/components/atoms/ToolTip/ToolTip'
import BagIcon from '@/components/icons/BagIcon'
import CalendarIcon from '@/components/icons/CalendarIcon'
import ChairIcon from '@/components/icons/ChairIcon'
import ClockIcon from '@/components/icons/ClockIcon'
import PinIcon from '@/components/icons/PinIcon'
import Logo from '@/components/atoms/Logo/Logo'

export type JobCardProps = Job

export default function JobCard({
  company,
  title,
  label,
  subTitle,
  location,
  remoteOption,
  startDate,
  endDate,
  duration,
  deadlineLabel,
  deadlineDate,
  employmentType,
  availabilityType,
  link,
  logo,
}: JobCardProps) {
  const { city, country } = location || {}
  const locationString =
    city?.title && country?.title
      ? `${city.title}, ${country.title}`
      : city?.title || country?.title || '-'
  const { locale } = useParams() as { locale: Locale }
  const dateLocale = i18n.dateLocales[locale] || 'en-GB'

  const [formattedEndDate, setFormattedEndDate] = useState<string | undefined>(undefined)
  const [formattedStartDate, setFormattedStartDate] = useState<string | undefined>(undefined)
  const [formattedDeadlineDate, setFormattedDeadlineDate] = useState<string | undefined>(undefined)

  // Set formatted dates - get the user's timezone from window.Intl
  useEffect(() => {
    const timeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone
    function formatDate(date: number | string | null | undefined, includeTime: boolean = false) {
      if (!date || typeof date === 'string') return undefined
      return (
        new Date(date).toLocaleDateString(dateLocale, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone,
          hour: includeTime ? 'numeric' : undefined,
          minute: includeTime ? 'numeric' : undefined,
        }) || undefined
      )
    }
    setFormattedEndDate(formatDate(endDate))
    setFormattedStartDate(formatDate(startDate))
    setFormattedDeadlineDate(formatDate(deadlineDate, true))
  }, [endDate, startDate, deadlineDate, dateLocale])

  return (
    <article className='bg-surface-solid p-4 border border-logo-icon relative h-full flex flex-col justify-between'>
      <div>
        <div className='h-16 py-2 mb-6'>
          {/* show Epico logo as fallback if job doesn't have a logo defined */}
          {logo ? (
            <img
              src={logo}
              alt={company}
              className='size-full object-contain block max-w-full max-h-full'
            />
          ) : (
            <Logo className='h-full mx-auto' />
          )}
        </div>
        {label && (
          <Text className='theme-brand-3 bg-accent px-4 py-2 absolute top-0 right-0'>{label}</Text>
        )}

        <div className='grid gap-1 mb-6'>
          <Text size='sm'>{company}</Text>
          <Heading tag='h2' size='xs'>
            {title}
          </Heading>
          <Text size='sm' className='line-clamp-3'>
            {subTitle}
          </Text>
        </div>
      </div>

      <div>
        <ul className='grid gap-2'>
          <li className='grid grid-cols-[auto,1fr] gap-4'>
            <PinIcon className='size-6' />
            <Text>{locationString}</Text>
          </li>
          <li className='grid grid-cols-[auto,1fr] gap-4'>
            <ChairIcon className='size-6' />
            <Text>{remoteOption?.title || '-'} </Text>
          </li>
          <li className='grid grid-cols-[auto,1fr] gap-4'>
            <BagIcon className='size-6' />
            <Text>
              {employmentType?.title || '-'}
              {availabilityType?.title && `, ${availabilityType?.title}`}
            </Text>
          </li>
          <li className='grid grid-cols-[auto,1fr] gap-4'>
            <CalendarIcon className='size-6' />
            <Text>{typeof startDate === 'string' ? startDate : formattedStartDate || '-'}</Text>
          </li>
          <li className='grid grid-cols-[auto,1fr] gap-4'>
            <ClockIcon className='size-6' />
            <Text>
              {duration && formattedStartDate && formattedEndDate ? (
                <ToolTip content={`${formattedStartDate} - ${formattedEndDate}`}>
                  {duration}
                </ToolTip>
              ) : (
                duration
              )}
            </Text>
          </li>
        </ul>

        {link && (
          <div className='mt-4'>
            {deadlineDate && (
              <p className='mb-1 text-xs text-text-tint'>
                {`${deadlineLabel || 'Deadline'}: ${
                  typeof deadlineDate === 'string' ? deadlineDate : formattedDeadlineDate || '-'
                }`}
              </p>
            )}
            {!deadlineDate && <div className='h-5' />}
            <Button href={link.href} prefetch={false} className='w-full flex justify-center'>
              {link.title}
            </Button>
          </div>
        )}
      </div>
    </article>
  )
}
