import { cn } from '@/lib/cn'

import CheckBox from '@/components/atoms/Forms/CheckBox/CheckBox'
import Text from '@/components/atoms/Text/Text'

export type JobListFilterGroupProps = {
  title?: string
  options: { title?: string; value?: string; subOptions?: { title?: string; value?: string }[] }[]
  currentFilters: string[]
  optionsCount: { [key: string]: number }
  onChange: React.ChangeEventHandler<HTMLInputElement>
  subGroup?: Omit<JobListFilterGroupProps, 'subGroup'>
}

export default function JobListFilterGroup({
  title,
  options,
  currentFilters,
  optionsCount,
  onChange,
  subGroup,
}: JobListFilterGroupProps) {
  if (!options.length || !title) return null

  return (
    <div className='grid gap-2'>
      <Text size='lg' className='font-bold'>
        {title}
      </Text>
      <ul className='grid gap-2'>
        {options.map((option) => {
          const { title, value, subOptions } = option
          if (!title || !value) return null
          return (
            <li key={value}>
              <CheckBox
                name={value}
                onChange={onChange}
                checked={currentFilters.includes(value)}
                indeterminate={currentFilters.find((f) => f.startsWith(`${value}:`)) ? true : false}
                disabled={!optionsCount[value] && !currentFilters.includes(value)}
              >
                {title} {optionsCount[value] || '0'}
              </CheckBox>

              {subOptions && (
                <div
                  className={cn('pl-6 mt-2', {
                    hidden: !currentFilters.includes(value || ''),
                  })}
                >
                  <ul className='grid gap-2'>
                    {subOptions.map((subOption) => {
                      const { title, value } = subOption
                      if (!title || !value) return null
                      return (
                        <li key={value}>
                          <CheckBox
                            name={value}
                            onChange={onChange}
                            checked={currentFilters.includes(value)}
                            disabled={!optionsCount[value] && !currentFilters.includes(value)}
                          >
                            {title} {optionsCount[value] || '0'}
                          </CheckBox>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </li>
          )
        })}
        {subGroup && (
          <>
            {subGroup.options.map((item) => {
              const { title, value } = item
              if (!title || !value) return null
              return (
                <li key={item.value}>
                  <CheckBox
                    name={value}
                    onChange={subGroup.onChange}
                    checked={subGroup.currentFilters.includes(value)}
                    indeterminate={
                      subGroup.currentFilters.find((f) => f.startsWith(`${value}:`)) ? true : false
                    }
                    disabled={
                      !subGroup.optionsCount[value] && !subGroup.currentFilters.includes(value)
                    }
                  >
                    {title} {subGroup.optionsCount[value] || '0'}
                  </CheckBox>
                </li>
              )
            })}
          </>
        )}
      </ul>
    </div>
  )
}
