'use client'

import type { Job } from '@/types/job'

import { useParams, useSearchParams } from 'next/navigation'
import { getOptionsCount } from './getOptionsCount'

import Container from '@/components/atoms/Container/Container'
import JobCard from './JobCard'
import JobListFilter from './JobListFilter'
import Text from '@/components/atoms/Text/Text'
import { useEffect, useState } from 'react'
import { cn } from '@/lib/cn'
import { searchBrainhunt } from '@/lib/brainhunt/loaders/searchBrainhunt'
import { i18n, Locale } from '@/i18n'
import SearchField from '@/components/atoms/Forms/SearchField/SearchField'

export type FilterGroup = {
  title?: string
  options: {
    title?: string
    value?: string
    subOptions?: { title?: string; value?: string }[]
  }[]
  subGroup?: Omit<FilterGroup, 'subGroup'>
}

export type JobListProps = {
  jobs: Job[]
  filterAriaTitle: string
  resetButtonText: string
  searchFieldText: string
  filterOptions?: {
    remoteOptionFilters: FilterGroup
    availabilityTypeFilters: FilterGroup
    employmentTypeFilters: FilterGroup
    countryFilters: FilterGroup
  }
}

export default function JobList({
  jobs: initialJobs,
  filterAriaTitle,
  resetButtonText,
  searchFieldText,
  filterOptions,
}: JobListProps) {
  const searchParams = useSearchParams()
  const [isLoadng, setIsLoading] = useState(false)
  const [jobs, setJobs] = useState<Job[]>(initialJobs)
  const [searchTerm, setSearchTerm] = useState('')
  const { locale, slug } = useParams()

  // Search term effect and debounce
  useEffect(() => {
    setIsLoading(true)
    const debounceTimeout = setTimeout(
      () => {
        if (searchTerm.length > 0) {
          searchJobs()
        } else {
          setIsLoading(false)
          setJobs(initialJobs)
        }
      },
      searchTerm.length > 0 ? 300 : 0,
    )

    async function searchJobs() {
      const jobs = await searchBrainhunt({
        searchTerm,
        locale: (locale || i18n.dateLocales) as Locale,
        pathname: `/${slug}`,
      })
      setJobs(jobs)
      setIsLoading(false)
    }

    return () => clearTimeout(debounceTimeout)
  }, [searchTerm])

  // Get filters from the URL params
  function getURLParamFilters(param: string) {
    return searchParams.get(param)?.split(',').filter(Boolean) || []
  }

  // Set filters to the URL params
  function setURLParamFilters(param: string, event: React.ChangeEvent<HTMLInputElement>) {
    let newValues = getURLParamFilters(param)
    // If checked, add the filter to the URL params
    if (event.target.checked) newValues.push(event.target.name)
    else {
      // If unchecked, remove the filter from the URL params - including sub-options
      newValues = newValues.filter((filter) => {
        return filter !== event.target.name && !filter.startsWith(`${event.target.name}:`)
      })
    }
    const url = new URL(window.location.href)
    // If no param values, remove the param from the URL - just eye candy
    if (newValues.length) url.searchParams.set(param, newValues.join(','))
    else url.searchParams.delete(param)
    window.history.replaceState(null, '', url)
  }

  // Reset the URL params
  function resetURLParamFilters() {
    const url = new URL(window.location.href)
    url.searchParams.delete('employment')
    url.searchParams.delete('remote')
    url.searchParams.delete('location')
    window.history.replaceState(null, '', url)
  }

  // Set current filters
  const currentFilters = {
    employment: getURLParamFilters('employment'),
    remote: getURLParamFilters('remote'),
    location: getURLParamFilters('location'),
    availability: getURLParamFilters('availability'),
  }

  // Get options count
  const optionsCount = {
    employment: getOptionsCount(jobs, currentFilters, 'employment'),
    remote: getOptionsCount(jobs, currentFilters, 'remote'),
    location: getOptionsCount(jobs, currentFilters, 'location'),
    availability: getOptionsCount(jobs, currentFilters, 'availability'),
  }

  // Filter jobs by filter group
  function filterByFilterGroup(
    job: Job,
    type: 'employment' | 'remote' | 'location' | 'availability',
  ) {
    const filters = currentFilters[type]
    if (!filters.length) return true
    return currentFilters[type].some((filter) => {
      // If the filter option has a selected sub-option, don't check by that filter option
      if (filters.find((f) => f.startsWith(`${filter}:`))) return false
      // If the filter option is a sub-option, check by that sub-option
      if (filter.includes(':')) {
        if (type === 'location') return job.location?.city?.value === filter
        return false
      }
      // Otherwise, check by the filter option
      if (type === 'availability') return job.availabilityType?.value === filter
      if (type === 'employment') return job.employmentType?.value === filter
      if (type === 'remote') return job.remoteOption?.value === filter
      if (type === 'location') return job.location?.country?.value === filter

      return false
    })
  }

  const filteredJobs = jobs.filter(
    (job) =>
      filterByFilterGroup(job, 'employment') &&
      filterByFilterGroup(job, 'availability') &&
      filterByFilterGroup(job, 'remote') &&
      filterByFilterGroup(job, 'location'),
  )

  return (
    <Container className='_jobList sm:grid grid-cols-[auto,1fr] items-start gap-6'>
      <JobListFilter
        filterAriaTitle={filterAriaTitle}
        resetButtonText={resetButtonText}
        searchTerm={searchTerm}
        onReset={() => {
          setSearchTerm('')
          resetURLParamFilters()
        }}
        groups={[
          {
            title: filterOptions?.employmentTypeFilters?.title,
            options: filterOptions?.employmentTypeFilters.options || [],
            currentFilters: currentFilters.employment,
            optionsCount: optionsCount.employment,
            onChange: (event) => {
              setURLParamFilters('employment', event)
            },
            subGroup: {
              title: filterOptions?.availabilityTypeFilters?.title,
              options: filterOptions?.availabilityTypeFilters?.options || [],
              currentFilters: currentFilters.availability,
              optionsCount: optionsCount.availability,
              onChange: (event) => {
                setURLParamFilters('availability', event)
              },
            },
          },
          {
            title: filterOptions?.remoteOptionFilters.title,
            options: filterOptions?.remoteOptionFilters.options || [],
            currentFilters: currentFilters.remote,
            optionsCount: optionsCount.remote,
            onChange: (event) => {
              setURLParamFilters('remote', event)
            },
          },
          {
            title: filterOptions?.countryFilters.title,
            options: filterOptions?.countryFilters.options || [],
            currentFilters: currentFilters.location,
            optionsCount: optionsCount.location,
            onChange: (event) => {
              setURLParamFilters('location', event)
            },
          },
        ]}
      />

      <div>
        <div className='mb-6'>
          <SearchField
            label={searchFieldText}
            className='!rounded-none'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='relative'>
          <ul
            className={cn('grid gap-4 lg:grid-cols-2 2xl:grid-cols-3', {
              'opacity-50 transition-opacity duration-200 pointer-events-none': isLoadng,
            })}
          >
            {jobs.length === 0 && <Text>No results</Text>}
            {filteredJobs.map((job) => {
              if (!job) return null
              return (
                <li key={job.id} className='h-full'>
                  <JobCard {...job} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Container>
  )
}
