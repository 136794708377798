import _IconWrapper, { IconBaseProps } from './_IconWrapper'

export default function MailIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
        <path
          d='M14.6654 4.00032C14.6654 3.26699 14.0654 2.66699 13.332 2.66699H2.66536C1.93203 2.66699 1.33203 3.26699 1.33203 4.00032M14.6654 4.00032V12.0003C14.6654 12.7336 14.0654 13.3336 13.332 13.3336H2.66536C1.93203 13.3336 1.33203 12.7336 1.33203 12.0003V4.00032M14.6654 4.00032L7.9987 8.66697L1.33203 4.00032'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </_IconWrapper>
  )
}
