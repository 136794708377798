import _IconWrapper, { IconBaseProps } from './_IconWrapper'

export default function SearchIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.33333 2.5C4.66396 2.5 2.5 4.66396 2.5 7.33333C2.5 10.0027 4.66396 12.1667 7.33333 12.1667C8.63661 12.1667 9.8194 11.6508 10.6887 10.8122C10.705 10.7891 10.7235 10.7671 10.7441 10.7465C10.7648 10.7258 10.7869 10.7072 10.8101 10.6909C11.65 9.8214 12.1667 8.6377 12.1667 7.33333C12.1667 4.66396 10.0027 2.5 7.33333 2.5ZM11.7955 11.0908C12.6511 10.0758 13.1667 8.76476 13.1667 7.33333C13.1667 4.11167 10.555 1.5 7.33333 1.5C4.11167 1.5 1.5 4.11167 1.5 7.33333C1.5 10.555 4.11167 13.1667 7.33333 13.1667C8.76354 13.1667 10.0735 12.652 11.0882 11.7977L13.6441 14.3536C13.8394 14.5489 14.1559 14.5489 14.3512 14.3536C14.5465 14.1583 14.5465 13.8417 14.3512 13.6465L11.7955 11.0908Z'
          fill='currentColor'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </_IconWrapper>
  )
}
