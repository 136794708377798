import { cn } from '@/lib/cn'

import CheckMarkIcon from '@/components/icons/CheckMarkIcon'
import MinusIcon from '@/components/icons/MinusIcon'

type CheckBoxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  children: React.ReactNode
  indeterminate?: boolean
}

export default function CheckBox({
  children,
  className,
  indeterminate = false,
  ...props
}: CheckBoxProps) {
  return (
    <label
      className={cn(
        '_checkbox inline-grid grid-cols-[auto_1fr] gap-2 items-center cursor-pointer [&:has(input:disabled)]:opacity-30',
        className,
      )}
    >
      <span className='flex relative items-center justify-center'>
        {!indeterminate && (
          <CheckMarkIcon className='size-4 absolute top-0 left-0 opacity-0 [&:has(+input:checked)]:opacity-100' />
        )}
        {indeterminate && (
          <MinusIcon className='size-4 absolute top-0 left-0 opacity-0 [&:has(+input:checked)]:opacity-100' />
        )}
        <input
          type='checkbox'
          className={cn(
            'appearance-none size-4 bg-surface-solid rounded-sm border border-stroke cursor-pointer',
            'checked:bg-surface-cta checked:border-surface-cta',
          )}
          {...props}
        />
      </span>
      {children}
    </label>
  )
}
