import _IconWrapper, { IconBaseProps } from './_IconWrapper'

export default function XIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
        <path d='M12 4L4 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4 4L12 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </_IconWrapper>
  )
}
