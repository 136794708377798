import ChevronDownIcon from '@/components/icons/ChevronDownIcon'
import { cn } from '@/lib/cn'

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement>

export default function Select({ className, ...props }: SelectProps) {
  return (
    <div className='relative'>
      <select
        className={cn(
          'appearance-none cursor-pointer block p-3 pr-10 rounded-md border border-text w-full bg-surface-solid',
          '',
          className,
        )}
        {...props}
      />
      <ChevronDownIcon className='size-4 absolute right-3 top-1/2 -translate-y-1/2' />
    </div>
  )
}
