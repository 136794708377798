import SearchIcon from '@/components/icons/SearchIcon'
import { cn } from '@/lib/cn'
import { InputHTMLAttributes } from 'react'

type SearchFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder'> & {
  label: string
}

export default function SearchField({ label, className, ...props }: SearchFieldProps) {
  return (
    <label
      className={cn(
        'p-3 border border-text rounded-md flex items-center gap-2 bg-surface-solid',
        'outline-on-child-focus-visible',
        className,
      )}
    >
      <span className='sr-only'>{label}</span>

      <SearchIcon className='size-4' aria-hidden />
      <input
        type='text'
        placeholder={label}
        {...props}
        className='placeholder:text-text inline-block w-full bg-transparent'
        {...props}
      />
    </label>
  )
}
