'use client'

import InfoIcon from '@/components/icons/InfoIcon'
import { cn } from '@/lib/cn'
import { useState, useId } from 'react'

type ToolTipProps = {
  children: string
  position?: 'top' | 'bottom'
  className?: string
  content?: string
}

export default function ToolTip({ children, content, position = 'top', className }: ToolTipProps) {
  const [isVisible, setIsVisible] = useState(false)
  const id = useId().replaceAll(':', '')
  const tooltipId = `tooltip-${id}`

  return (
    <span className='inline-block'>
      <span className='inline-flex items-center gap-[0.5ch]'>
        {children}
        <span
          tabIndex={0}
          aria-describedby={tooltipId}
          className='cursor-help relative group size-4 flex items-center justify-center'
          onClick={() => setIsVisible(!isVisible)}
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
          onFocus={() => setIsVisible(true)}
          onBlur={() => setIsVisible(false)}
        >
          <InfoIcon className='size-full' />
          <span
            id={tooltipId}
            role='tooltip'
            className={cn(
              'absolute z-10 px-4 py-2 bg-surface-solid border border-stroke w-max max-w-64 text-sm transition-[opacity,transform] duration-200 ease-in-out',
              {
                '-top-2 left-1/2 -translate-x-1/2 -translate-y-[calc(100%-0.25rem)]':
                  position === 'top',
                '-translate-y-full': position === 'top' && isVisible,
                '-bottom-2 left-1/2 -translate-x-1/2 translate-y-[calc(100%+0.25rem)]':
                  position === 'bottom',
                'translate-y-full': position === 'bottom' && isVisible,
                'opacity-0 pointer-events-none': !isVisible,
                'opacity-100 pointer-events-auto': isVisible,
              },
              className,
            )}
          >
            {content}
          </span>
        </span>
      </span>
    </span>
  )
}
