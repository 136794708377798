import _IconWrapper, { IconBaseProps } from './_IconWrapper'

export default function ChevronDownIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
        <path
          d='M4 6L8 10L12 6'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </_IconWrapper>
  )
}
